<template>
  <div class="flex w-fit h-fit gap-4 flex-row">
    <a
      href="https://github.com/vihan246"
      class="w-8 h-8 mx-4 group"
      target="_blank"
    >
      <svg
        viewBox="0 0 98 96"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-background-light group-hover:fill-pop transition duration-300"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z"
        />
      </svg>
    </a>

    <a
      href="https://www.linkedin.com/in/vihan-raj-217067220/"
      class="w-8 h-8 group mx-4"
      target="_blank"
    >
      <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-background-light group-hover:fill-pop transition duration-300"
      >
        <path
          d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z"
        />
        <path
          d="M5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44771 8 10V18C8 18.5523 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V10Z"
        />
        <path
          d="M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z"
        />
      </svg>
    </a>

    <a
      href="mailto:vihanraj@usc.edu"
      target="_blank"
      class="w-8 h-8 group mx-4"
    >
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="stroke-background-light stroke-2 group-hover:stroke-pop transition duration-300"
      >
        <path
          d="M 19.5 9.143 L 16.533 10.555 C 14.878 11.344 14.051 11.737 13.175 11.892 C 12.4 12.028 11.6 12.028 10.825 11.892 C 9.949 11.737 9.122 11.344 7.467 10.555 L 4.5 9.143 M 7.166 17 L 16.834 17 C 17.767 17 18.233 17 18.59 16.845 C 18.904 16.707 19.159 16.489 19.318 16.22 C 19.5 15.915 19.5 15.515 19.5 14.715 L 19.5 9.285 C 19.5 8.485 19.5 8.085 19.318 7.78 C 19.159 7.511 18.904 7.293 18.59 7.155 C 18.233 7 17.767 7 16.834 7 L 7.166 7 C 6.233 7 5.767 7 5.41 7.155 C 5.096 7.293 4.841 7.511 4.682 7.78 C 4.5 8.085 4.5 8.485 4.5 9.285 L 4.5 14.715 C 4.5 15.515 4.5 15.915 4.682 16.22 C 4.841 16.489 5.096 16.707 5.41 16.845 C 5.767 17 6.233 17 7.166 17 Z"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect x="1" y="1" width="22" height="22" rx="2" ry="2" />
      </svg>
    </a>
  </div>
</template>
<script>
export default {
  name: "SocialIcons.vue",
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
};
</script>
